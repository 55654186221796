.scrolling-text{
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    .text-wrapper{
        overflow: hidden;
        width: 100%;
        display: flex;
        @keyframes scrollToLeft {
          0%{
            transform: translate(0,0);
          }
          100%{
            transform: translate(-100%,0);
          }
        }
        @keyframes scrollToRight {
          0%{
            transform: translate(-100%,0);
          }
          100%{
            transform: translate(0,0);
          }
        }
        .text{
          color: $color-4;
          white-space: nowrap;
          font-size : 42px;
        }
        .toleft{
          animation: scrollToLeft 30s linear infinite;
        }
        .toright{
          animation: scrollToRight 30s linear infinite;
        }
    }
}

