html{
    scroll-snap-type: y proximity;
    scroll-behavior: smooth;
    overflow-y: scroll;
    scroll-padding-top: 85px;
}
.services{
    scroll-snap-align: start;
    overflow-y: auto;
}
.presentation{
    scroll-snap-align: start;
    overflow-y: auto;
}