.menu-div{
    background-color: $color-3;
    .menu-grid{
        display: flex;
        min-height: 400px;
        .menu-right{
            width: 45%;
            display: flex;
            .border-left{
                margin-top: auto;
                margin-bottom: auto;
                height: 70%;
                width: 1px;
                position: relative;
                background: $color-1;
            }
        }
        .menu-left{
            width: 55%;
            padding-top: 20px;
            h3{
                font-size: 2.5rem;
                font-family: $font-5;
                color: $color-4;
                padding-left: 20px;
            }
            .image-container{
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;
                @keyframes lefttoright{
                    50%{
                      transform: translate(-30px, 10px);
                    }
                }
                @keyframes righttoleft{
                    50%{
                      transform: translate(50px, -20px);
                    }
                }
                .house1{
                    top: 0px;
                    width: 220px;
                    left: 30px;
                    z-index: 4;
                    animation: 6s ease-in-out 0s infinite normal none running lefttoright;
                }
                .house2{
                    top: 20px;
                    width: 350px;
                    left: 150px;
                    z-index: 5;
                    animation: 8s ease-in-out 0s infinite normal none running righttoleft;
                }
                .house3{
                    top: -20px;
                    left: 320px;
                    width: 180px;
                    z-index: 4;
                    animation: 7s ease-in-out 0s infinite reverse none running lefttoright;
                }
                img{
                    position: absolute;
                }
            }
        }
    }
}