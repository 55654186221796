.avantages{
    padding-top: 50px;
    background: linear-gradient(90deg, $color-2 0%, $color-2 550px,$color-3 550px, $color-3 70%);
    font-family: $font-3;
    font-size: 1.2rem;
    h3{
        color: $color-2;
        font-size: 2rem;
        margin-bottom: 7px;
        font-family: $font-4;
    }
    .col{
        display: flex;
        flex-direction: column;
    }
    .flex{
        display: flex;
        align-items: center;
    }

    .exception{
        justify-content: flex-end;
        width: 80%;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.60) 0px 5px 15px;
        padding: 15px;
        .manoir{
            max-width: 370px;
        }
        .exception-text{
            margin-right: 10%;
            max-width: 700px;
        }
    }
    .accompagnement{
        padding: 25px;
        margin-left: 50px;
        .accompagnement-text{
            margin-left: 200px;
            max-width: 700px;
        }
        .aide{
            max-width: 420px;
        }
    }
}