.footer{
    background-color: $color-2;
    font-family: $font-2;
    font-size: 1.2rem;
    color: white;
    padding-top: 20px;
    .flex{
        display: flex;
    }
    .footer-col{
        display: flex;
        flex-direction: column;
        padding: 10px;
        margin-left: 50px;
        padding-bottom: 30px;
    }
    .copyright{
        display: flex;
        padding: 10px;
        justify-content: center;
        border-top: 3px solid white;
    }
}