.comment-card{
    background-color: $color-3;
    border-radius: 35px;
    width: 385px;
    box-shadow: rgba(17, 17, 26, 0.2) 0px 0px 16px;
    padding: 20px;
    position: relative;
    .comment-header{
        display: flex;
        margin-bottom: 15px;
        .star{
            width: 17px;
        }
        .col{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .name-container{
            margin-left: 10px;
            font-family: $font-3;
            .name{
                font-size: 1rem;
            }
            .desc{
                font-size: 0.8rem;
            }
        }
        .avatar{
            height: 80px;
            width: 80px;
            object-fit: cover;
            border-radius: 50%;
            border: 3px solid $color-1;
        }
    }
    .comment-content{
        font-style: italic;
        margin-bottom: 10px;
    }
    .date{
        position: absolute;
        right: 20px;
        bottom: 10px;
        font-family: $font-4;
    }
}