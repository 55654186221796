.services{
    background-color: $color-3;
    padding-top: 20px;
    padding-bottom: 20px;
    h3{
        color: $color-2;
        font-size: 1.6rem;
        font-family: $font-2;
    }
    h4{
        color: $color-2;
        font-size: 1.4rem;
        font-family: $font-1;
    }
    .flex{
        display: flex;
    }
    .text{
        text-align: justify;
        span{
            color: $color-2;
        }
    }
    .services-grid{
        display: grid;
        grid-template-columns: 65% 35%;
        .left{
            margin-right: 25px;
        }
        .cibler{    
            .houses{
                height: 15rem;
            }
            .text{
                margin-left: 20px;
            }
            .col{
                flex-direction: column;
                justify-content: space-between;
            }
            .icons-container {
                width: 100%;
                justify-content: space-around;
                .flex{
                    align-items: center;
                }
                .icons{
                    height: 2.4rem;
                    margin-right: 12px;
                }
            }
        }
        .recherche{
            margin-top: 5px;
            .text{
                margin-right: 20px;
                margin-top: auto;
                margin-bottom: auto;
            }
            .house{
                height: 8rem;
            }  
        }
        .financement{
            margin-top: 5px;
        }
        .patrimoine{
            .de-container{
                justify-content: space-evenly;
                .de{
                    height: 5.5rem;
                }
            }
        }
    }
}