.services-vente{
    .top-container{
        background: $color-3;
        padding-bottom: 200px;
        padding-top: 30px;
        .flex-line{
            display: flex;
            .left-container{
                width: 55%;
                display: flex;
                align-items: center;
                .text-container{
                    display: flex;
                    flex-direction: column;
                    width: 80%;
                    h3{
                        margin-bottom: 8px;
                        font-family: $font-4;
                        font-size: 1.6rem;
                        color: $color-2;
                    }
                    span{
                        font-family: $font-3;
                        text-align: justify;
                    }
                }
                img{
                    margin-left: auto;
                    margin-right: auto;
                }
                @keyframes wiggle {
                    0% {transform: rotate(10deg);}
                    25% {transform: rotate(-10deg);}
                    50% {transform: rotate(20deg);}
                    75% {transform: rotate(-5deg);}
                    100% {transform: rotate(0deg);}
                  }
                .billets{
                    width: 100px;
                }
                .pieces{
                    width: 82px;
                }
                .wiggle{
                    animation: 1s ease-in-out 0s 1 wiggle;
                }
            }
            .cube{
                width: 22.5%;
                .picto{
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    h4{
                        padding-bottom: 10px;
                        text-align: center;
                        color: white;
                    }
                    img{
                        width: 100px;
                        height: 101px;
                        margin: 15px auto 15px auto;
                    }
                }
            }
            .delais{
                background: #71B2D2;
            }
            .protection{
                background: #95C5DD;
            }
            .satisfaction{
                background: #4E9EC6;
            }
            .assurance{
                background: #23749C;
            }
        }
    }
    .immovable-type{
        margin-top: -185px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        .slider-frame{
            margin-top: -90px;
        }
        .blue-square{
            border-radius: 0.6rem;
            padding: 20px;
            width: 500px;
            height: 230px;
            background-color: #D2E6F7;
            margin-left: auto;
            margin-right: auto;
            font-family: $font-1;
            text-align: center;
            color: $color-2;
            h3{
                font-size: 2rem;
                font-family: $font-5;
                text-transform: uppercase;
                margin-bottom: 10px;
            }
            span{
                font-size: 1.3rem;
                font-family: $font-3;
            }
        }
    }
}