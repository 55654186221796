* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
    outline: none;
    border: none;
    text-decoration: none;
    font-size: 17px;
    scrollbar-width: none;
    &::-webkit-scrollbar{
        display: none;
    }
    &::selection {
      background: $color-1;
    }
    h3{
        font-family: $font-4;
        font-size: 2rem;
        color: $color-2;
    }
    a{
        color: white;
    }
    a:hover{
        color: $color-1;
    }
    .wrapper{
        margin-right: auto;
        margin-left:  auto;
      
        max-width: 75%;
      
        padding-right: 10px;
        padding-left:  10px;
    }
    .spinner-container
    {   
        width: 100%;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
