.besoin-louer{
    padding-top: 20px;
    h3{
        text-align: center;
        font-size: 2.5rem;
        color: $color-4;
    }
    .flex-cards{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }
    .card{
        padding: 20px;
        height: 300px;
        width: 400px;
        background-color: $color-3;
        border-radius: 2rem;
        box-shadow: rgba(17, 17, 26, 0.2) 0px 0px 16px;
        transition: all 0.5s ease-in;
        position: relative;
        .card-text{
            pointer-events: none;
            margin-top: 10px;
            opacity: 0;
            transition: opacity 0.5s ease-in;
        }
        .video-container  {
            pointer-events: none;
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
        }
        video{
            position: relative;
        }
        .localite-mask{
            mask-image:url(../../components/BesoinLouer/assets/mask-localite.png);
            mask-position: center right;
            mask-repeat:no-repeat;
            mask-size: contain;
            width: 250px;
            video{
                left: -70px;
            }
        }
        .type-mask{
            mask-image:url(../../components/BesoinLouer/assets/mask-type.png);
            mask-position:center right;
            mask-repeat:no-repeat;
            mask-size: contain;
            width: 200px;
            video{
                left: -80px;
                bottom: -20px;
            }
        }
        .comodite-mask{
            mask-image:url(../../components/BesoinLouer/assets/mask-canape.png);
            mask-position: bottom right;
            mask-repeat:no-repeat;
            mask-size: contain;
            width: 320px;
            margin-bottom: 40px;
            video{
                left: -45px;
                bottom: -45px;
            }
        }
        h4{
            text-align: center;
            color: $color-2;
            font-size: 2.5rem;
            font-family: $font-1;
            transition: color 0.2s ease-in;
        }
    }
    .card:hover{
        background-color: $color-2;
        .card-text{
            color: white;
        }
        h4{
            color: white;
        }
    }
}