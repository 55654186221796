.pourquoiacheter{
    display: flex;
    margin-top: 40px;
    .left{
        display: flex;
        flex-direction: column;
        border-right: 1px solid;
        border-left: 0px solid;
        border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #29292924 25%, #29292924 75%, rgba(0, 0, 0, 0)) 1 100%;
        img{
            max-width: 450px;
        }
        span{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: $color-4;
            margin-bottom: 1.5rem;
            border-radius: 62.5rem;
            padding: 0.375rem 0.75rem;
            color: white;
            font-size: 1.1rem;
            width: fit-content;
        }
        h3{
            max-width: 424px;
            margin-bottom: 4rem;
            margin-right: 5rem;
        }
    }
    .right{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        h4{
            color: $color-2;
            font-size: 1.4rem;
        }
        p{
            margin-top: 0.75rem;
        }
        .square{
            margin-top: 1.25rem;
            border: 1px solid $color-2;
            padding: 1rem;
            border-radius: 1rem;
            width: fit-content;
            display: flex;
        }
        .top{
            width: 100%;
            display: flex;
            .top-left{
                border-right: 1px solid;
                border-left: 0px solid;
                border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #29292924 50%) 1 100%;
                padding: 0.5rem;
                .top-left-content{
                    height: 414px;
                    display: flex;
                    flex-direction: column;
                    padding: 1.5rem;
                    border-radius: 1rem;
                    position:relative;
                    .svgHouse{
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                    }
                }

            }
            .top-right{
                padding: 0.5rem;
                .top-right-content{
                    height: 414px;
                    display: flex;
                    flex-direction: column;
                    padding: 1.5rem;
                    border-radius: 1rem;
                }
            }
        }
        .bottom{
            width: 100%;
            background-image: linear-gradient(to right, #29292924, rgba(0,0,0,0));
            background-size: 100% 1px;
            background-repeat: no-repeat;
            background-position: center top;
            padding: 0.5rem;
            .bottom-content{
                height: 286px;
                padding: 1.5rem;
                border-radius: 1rem;
                display: flex;
                .bottom-text{
                    margin-right: 10rem;
                }
            }
            .slider-icon{
                display: flex;
                flex-direction: column;
                .bar{
                    width: 240px;
                    height: 67px;
                    border-radius: 62.5rem;
                    background-color: #2A8BBB;
                }
                .icon-top{
                    margin-bottom: 2.125rem;
                    position: relative;
                    width: fit-content;
                    .dot{
                        width: 54px;
                        height: 54px;
                        right: 7px;
                        top: 7px;
                        position: absolute;
                        background-color: white;
                        border-radius: 100%;
                    }
                }
                .icon-bottom{
                    position: relative;
                    width: fit-content;
                    .dot{
                        width: 54px;
                        height: 54px;
                        left: 7px;
                        top: 7px;
                        position: absolute;
                        background-color: white;
                        border-radius: 100%;
                    }
                }
            }
        }
    }

}