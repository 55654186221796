.menu-wrapper{
    border-radius: 5px;
    padding: 1vw;
    .menu-container{
        border-radius: 7px;
        .title-row{
            display: flex;
            justify-content: space-between;
            border-top: 1px solid $color-4;
            .rotate{
                transform: rotate(180deg);
            }
            .arrow{
                margin-top: auto;
                margin-bottom: auto;
                margin-right: 5px;
                height: 15px;
                width: auto;
                transition: transform .4s ease-in;  
            }
            .title{
                font-size: 1.3rem;
                font-family: $font-5;
                color: $color-2;
                white-space: nowrap;
                overflow: hidden;
                padding: 3px;
            }
        }
        .text-container{
            font-family: $font-2;
            transition: height ease 0.9s;
            overflow: hidden;
        }
    }
}