@font-face {
    font-family: "agency";
    src: url(./assets/fonts/agencyr.ttf);
}
@font-face {
    font-family: "minion";
    src: url(./assets/fonts/minion.otf);
}
@font-face {
    font-family: "bell";
    src: url(./assets/fonts/BellMT.woff2);
}
@font-face {
    font-family: "baskvill";
    src: url(./assets/fonts/baskvill.woff2);
}
@font-face {
    font-family: "copperplate";
    src: url(./assets/fonts/Copperplate.ttf);
}
$font-1: "agency";
$font-2: "minion";
$font-3: "bell";
$font-4: "baskvill";
$font-5: "copperplate";
$color-1: #79cdf4;
$color-2: #2A8BBB;
$color-3: #f5f9fc;
$color-4: #F48F64;
$color-5: #8F8F8C;

:export {
    color1 : $color-1;
    color2 : $color-2;
    color3 : $color-3;
    color4 : $color-4;
    color5 : $color-5;
    font1 : $font-1;
    font2 : $font-2;
    font3 : $font-3;
    font4 : $font-4;
    font5 : $font-5;
}