.page-header{
    background: url(../../components/PageHeader/assets/gradient.png);
    background-position-x: center;
    background-size: 100% 100%;
    padding-top: 85px;
    padding-bottom: 20px;
    .flex{
        display: flex;
        align-items: center;
        margin-top: 20px;
        .left-flex{
            position: relative;
            display: block;
        }
    }
    .title{
        font-family: $font-2;
        text-transform: uppercase;
        line-height: 5vw;
        margin-left: -4%;
        .title-1{
            color: #F48F64;
            font-size: 5.5vw;
        }
        .title-2{
            color: #F8B498;
            font-size: 5.5vw;
        }
        .title-3{
            color: #FBDACB;
            font-size: 5.5vw;
        }
        .title-4{
            color: white;
            font-size: 5.5vw;
        }
    }
    .star-col{
        width: 20%;
        display: flex;
        flex-direction: column;
    }
    .star1{
        width: 60%;
    }
    .star2{
        margin-top: 10px;
        width: 90%;
        margin-left: auto;
    }
    .house{
        width: 30%;
        margin-left: auto;
    }

}