.resultats{
    margin-top: 20px;
    h3{
        text-align: center;
        font-family: $font-2;
        color: $color-2;
        font-size: 2rem;
    }
    .stats-container{
        display: flex;
        justify-content: center;
        .stats{
            height: 9rem;
        }
        .text{
            width: 500px;
            margin-left: 50px;
            margin-top: auto;
            margin-bottom: auto;
            span{
                color: $color-2;
            }
        }
    }
    .comments-container{
        margin-top: 40px;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-evenly;
    }

}