.nav{
    width: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: $color-2;
    color: white;
    font-family: $font-2;
    font-size: 1.4rem;
    text-transform: uppercase;
    padding-top: 5px;
    .flex{
        align-items: center;
        display: flex;
        .logo{
            width: 80px;
        }
        a{
            margin-right: 40px;
            font-size: 1.4rem;
        }
    }
    .right-nav{
        margin-left: auto;
        align-items: center;
        display: flex;
    }
    .logo-typo{
        height: 60px;
    }
    .active{
        color: $color-4;
        font-weight: bold;
    }
}
