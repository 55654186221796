.presentation{
    position: relative;
    color: white;
    .home-bg{
        height: 100%;
        width: 100%;
    }
    .presentation-header{
        position: absolute;
        top: 0;
        width: 100%;
        .logo{
            float: left;
            margin-left: 30px;
            margin-top: 30px;
            width: 20%;
        }
        .description{
            h1{
                text-align: center;
                font-family: $font-2;
                font-size: 5vw;
            }
            h2{
                text-align: center;
                font-family: $font-1;
                font-size: 1.6vw;
                line-height: 2vw;
                margin-top: -1%;
                span{
                    font-size: 1.7vw;
                    color: $color-1;
                }
            }
        }
    }
    .presentation-button{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 2.7vw;
        font-weight: bold;
        font-size: 3vw;
        text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
        -webkit-text-stroke: 2px white;
        -webkit-text-fill-color: $color-2; 
        font-family: $font-5;
        text-transform: uppercase;
    }
    .presentation-button:hover{
        scale: 1.05;
    } 
    .footer-contact{
        position: absolute;
        bottom: 0;
        right: 60px;
    }
    .presentation-footer{
        position: absolute;
        bottom: 0;
        left: 60px;
        gap: 3vw;
        display: flex;
        justify-content: space-around;
    }
}