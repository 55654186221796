.key{
    .key-frame{
        width: 100px;
        height: 180px;
        position: absolute;
        z-index: 10;
    }
    .sticky-key{
        position: fixed;
        top: 84px;
    }
}